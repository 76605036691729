@import 'styles/layers.scss';

@layer component {
  .wrap {
    padding: 151px 80px;
    height: 100vh;
    width: 100vw;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .left {
    width: 30%;
    height: 100%;
  }

  .right {
    width: 70%;
    height: 100%;
    background-color: var(--black5);
  }

  .title {
    font-size: 22px;
    font-weight: 400;
  }

  .group {
    height: 106px;
  }

  .text {
    opacity: 0.3;
    margin: 0;
    width: 277px;
  }

  .link {
    color: inherit;
    &:hover, &:active {
      color: inherit;
    }
  }
}
